import MainNavbar, { links } from "components/Navbar/MainNavbar";

import FeatureSection from "../FeaturesSection";
import GetStartedSection from "../GetStartedSection";
import IndexFooter from "../../IndexFooter";
import UsernameInputForm from "../UsernameInputForm";

const SuperlinkLandingView = () => {
  return (
    <div className="flex flex-col min-h-screen bg-white">
      <MainNavbar isDark={false} showAuth/>
      <main className="flex-1">
        <div className="mt-16 md:mt-[121px]">
          <h1 className="text-[#222528] text-center font-nunito font-extrabold text-[32px] leading-[44px] md:text-[84px] md:leading-[120%] w-full px-6 mx-auto md:max-w-[1048px]">
            Boost your profile with a <br className="hidden md:block" />{" "}
            <span className="text-[#384AF1]">superlink</span>
          </h1>
        </div>

        {/* Username Input */}
        <div className="w-full px-9 mx-auto md:max-w-[516px] mt-11 md:mt-9">
          <UsernameInputForm isSuperlinkLandingTop />
        </div>
        {/* hero image */}
        <img
          className="md:hidden mt-[55px] mx-auto px-2"
          src="/assets/images/superlinks-hero-mobile.webp"
          alt="superlinks-demo-profile"
        />
        <img
          className="hidden md:block mt-[55px] mx-auto md:px-[50px] lg:px-[110px]"
          src="/assets/images/superlinks-hero-desktop.webp"
          alt="superlinks-demo-profile"
        />

        <div className="mt-9 md:mt-10">
          <FeatureSection />
        </div>

        <div className="mt-9 md:mt-10">
          <GetStartedSection />
        </div>

        <div className="mt-9 md:mt-10">
          <img
            src="/assets/images/wave-light.svg"
            alt="footer wave"
            className="hidden w-full select-none md:block"
          />
          <IndexFooter isLightMode={true} footerBackground="#F7F7F8" />
        </div>
      </main>
    </div>
  );
};

export default SuperlinkLandingView;
