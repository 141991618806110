import cls from "classnames";
import { useState } from "react";
import { InView } from "react-intersection-observer";

export default function FeatureSection() {
  const [animateSection, setAnimateSection] = useState({
    section1: false,
    section2: false,
  });

  function animateSectionById(id) {
    setAnimateSection({
      ...animateSection,
      [id]: true,
    });
  }

  return (
    <div className="flex flex-col gap-9 md:gap-10">
      <section className="px-[30px] py-4 md:px-[30px] md:py-[46px] lg:px-0">
        <div className="mx-auto w-full md:max-w-[1226px] flex flex-col md:flex-row justify-between items-center gap-6 md:gap-6 lg:gap-12">
          <div className="md:w-1/2 lg:w-auto md:max-w-[670px] text-center md:text-left md:mx-4">
            {/* Section 1 */}
            <InView
              onChange={(inView) => {
                if (inView) {
                  animateSectionById("section1");
                }
              }}
              rootMargin="-10% 0% -10% 0%"
              className={cls(
                "transition-all header-reveal-animation",
                { "translate-y-8 opacity-0": !animateSection.section1 },
                {
                  "translate-y-0 opacity-100 block": animateSection.section1,
                }
              )}
            >
              <span className="w-[250px] h-2 block mb-6 md:mb-7 mx-auto md:mx-0 bg-[#384AF1]" />
              <h2 className="font-extrabold text-[32px] leading-10 md:text-[56px] md:leading-[60px] lg:text-[64px] lg:leading-[68px] text-[#101010]">
                Highlight your work in your superlink
              </h2>
              <p className="mt-5 md:mt-7 text-[#101010] md:text-lg lg:text-2xl md:max-w-[602px]">
                Now you can highlight your top links in your profile and
                increase the number of clicks by your users
              </p>
            </InView>
          </div>
          <div className="md:w-[550px] md:h-[550px] md:flex md:items-center md:p-6">
            <img
              src="/assets/images/superlinks-categorise-mobile.webp"
              alt=""
              className="object-contain object-center max-w-md md:max-w-none w-full md:hidden"
            />
            <img
              src="/assets/images/superlinks-categorise-desktop.webp"
              alt=""
              className="object-contain object-center max-w-md md:max-w-none w-full hidden md:block"
            />
          </div>
        </div>
      </section>
      <section className="px-[30px] py-4 md:px-[30px] md:py-[46px] lg:px-0">
        <div className="mx-auto w-full md:max-w-[1226px] flex flex-col-reverse md:flex-row justify-between items-center gap-6 md:gap-6 lg:gap-12">
          <div className="md:w-[550px] md:h-[550px] md:flex md:items-center md:p-6">
            <img
              src="/assets/images/superlinks-feature-mobile.webp"
              alt=""
              className="object-contain object-center max-w-md md:max-w-none w-full md:hidden"
            />
            <img
              src="/assets/images/superlinks-feature-desktop.webp"
              alt=""
              className="object-contain object-center max-w-md md:max-w-none w-full hidden md:block"
            />
          </div>
          <div className="md:w-1/2 lg:w-auto mt-8 md:mt-0 md:max-w-[670px] text-center md:text-left md:mx-4">
            {/* Section 2 */}
            <InView
              onChange={(inView) => {
                if (inView) {
                  animateSectionById("section2");
                }
              }}
              rootMargin="-10% 0% -10% 0%"
              className={cls(
                "transition-all header-reveal-animation",
                { "translate-y-8 opacity-0": !animateSection.section2 },
                {
                  "translate-y-0 opacity-100 block": animateSection.section2,
                }
              )}
            >
              <span className="w-[250px] h-2 block mb-6 md:mb-7 mx-auto md:mx-0 bg-[#384AF1]" />
              <h2 className="font-extrabold text-[32px] leading-10 md:text-[56px] md:leading-[60px] lg:text-[64px] lg:leading-[68px] text-[#101010]">
                Categorise your links easily
              </h2>
              <p className="mt-5 md:mt-7 text-[#101010] md:text-lg lg:text-2xl md:max-w-[602px]">
                Put heading on your links and categorise them for showing
                category sections in your profile
              </p>
            </InView>
          </div>
        </div>
      </section>
    </div>
  );
}
