import {creatorOnboardingUrl} from "../../constants/constants";
import * as Track from "@qoohoo/common/utils/analytics/track";
import {button, pageview} from "@qoohoo/common/constants/analytics";

export default function UsernameInputForm() {
  return (
    <a
      href={creatorOnboardingUrl}
      target="_blank"
      rel="noopener"
      className="mt-9 md:mt-14 mx-auto flex justify-center items-center font-nunito sm:text-2xl font-bold text-white py-4 px-8 sm:px-[62px] w-max bg-[#384AF1] rounded-full button-solid-shadow-black hover:scale-105 duration-200 transition-all get-started-button"
      onClick={() => {
        // setIsGetStartedModalOpened(true);

        Track.buttonClick(button.become_creator, {
          current_screen: pageview.superlink,
        });
      }}
    >
      Become a Creator
    </a>
  );
}
