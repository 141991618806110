import cls from "classnames";
import { useState } from "react";
import { InView } from "react-intersection-observer";
import UsernameInputForm from "./UsernameInputForm";

const GetStartedSection = () => {
  const [animateSection, setAnimateSection] = useState(false);

  function triggerSectionAnimation() {
    setAnimateSection(true);
  }

  return (
    <section className={cls("py-16 md:py-14", "text-[#16191B]")}>
      <InView
        onChange={(inView) => {
          if (inView) {
            triggerSectionAnimation();
          }
        }}
        rootMargin="-10% 0% -10% 0%"
        className={cls(
          "transition-all header-reveal-animation",
          { "translate-y-8 opacity-0": !animateSection },
          {
            "translate-y-0 opacity-100 block": animateSection,
          }
        )}
      >
        <div className="text-center flex flex-col items-center">
          <img
            src="/assets/images/rocket-black.svg"
            alt="rocket"
            className="w-[57px] h-20 mr-[-14rem] md:-mr-96"
          />
          <h4 className="font-bold text-[32px] md:text-5xl font-inter">
            Let's get started...
          </h4>
        </div>
      </InView>

      <div className="w-full px-9 mx-auto md:max-w-[516px] mt-9 md:mt-10">
        <UsernameInputForm />
      </div>
    </section>
  );
};

export default GetStartedSection;
